import { gql } from '@apollo/client'

// Need to import this explicitly as there is an issue with
// the generated file when using the fragment, because it
// appears as though the fragment is not exported...
import { usePersistedCacheQuery } from 'app/hooks'

const query = gql`
  query fetchCategoryTabs {
    OfferCategoryMenu {
      liteMenuItems {
        id
        name
        slug
        icon
      }
    }
  }
`

export interface CategoryTabsQueryInput {}

export interface CategoryTabsQueryOutput {
  OfferCategoryMenu: {
    liteMenuItems: {
      id: string
      name: string
      slug: string
      icon?: string
    }[]
  }
}

export const useCategoryTabsQuery = (input: CategoryTabsQueryInput) =>
  usePersistedCacheQuery<CategoryTabsQueryOutput, CategoryTabsQueryInput>(query, { variables: input })

export const fetchCategoryTabsQuery = { query }
