import { addApolloState, initializeApollo } from 'lib/initialize-apollo'
import { withAuthedPage } from 'lib/session'
import { GetServerSideProps } from 'next'
import Head from 'next/head'
import { LandingScreen } from 'app/features/landing'
import { landingPageQuery } from 'app/features/landing/queries/use-landing-page.query'
import { fetchCategoryTabsQuery } from 'app/features/offer/containers/category-tabs/queries'

export default function Page() {
  return (
    <>
      <Head>
        <meta name="fo-verify" content="f38e0792-39e1-445a-9154-f82817957a4d" />
      </Head>
      <LandingScreen />
    </>
  )
}

export const getServerSideProps: GetServerSideProps = withAuthedPage(async ({ req, res }) => {
  const user = req?.session?.user

  const client = initializeApollo({ token: user?.token })
  await client.query({
    query: landingPageQuery.query,
  })

  await client.query({
    query: fetchCategoryTabsQuery.query,
  })

  // add cache headers if not authenticated
  if (!user) {
    res.setHeader('Cache-Control', 's-maxage=60, stale-while-revalidate')
  }

  return addApolloState(client, {
    props: {
      user: user || null,
    },
  })
})
