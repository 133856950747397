import { useCategoryTabsQuery } from './queries'
import { CategoryTabsBlock, CategoryTabsComponent } from '../../blocks'

export const CategoryTabsContainer = () => {
  const { data } = useCategoryTabsQuery({})

  const categories = data?.OfferCategoryMenu?.liteMenuItems?.map((item) => ({
    ...item,
    position: 0, // Legacy
  })) || [];

  const exploreCategory: CategoryTabsBlock['categories'][0] = {
    id: 'explore',
    name: 'Explore',
    slug: '',
    position: 0,
    icon: 'Flame',
  }

  const allCategories = [
    exploreCategory,
    ...categories,
    {
      id: 'brands-a-z',
      name: 'Brands A-Z',
      slug: '',
      icon: 'AZ',
      position: 0,
    },
  ]

  return <CategoryTabsComponent categories={allCategories} />
}
