import { SizableText, SmartLink, Stack, XStack, YStack, shadowStandard } from '@my/ui'
import AppStoreImage from 'app/assets/images/Appstore.svg'
import GooglePlayImage from 'app/assets/images/Playstore.svg'
import { SolitoImage } from 'solito/image'

export const LandingAppStoreLinks = () => {
  return (
    <YStack jc="center" ai="center" gap="$2">
      <SizableText tag="h2" size="$8.5" fow="600" ta="center">
        Download The App
      </SizableText>
      <XStack gap="$3">
        <SmartLink
          href={`https://apps.apple.com/us/app/student-saviour/id6450699794`}
          Component={Stack}
          aspectRatio={148 / 44}
          h={44}
          w={148}
          br="$3"
          focusStyle={{
            ...shadowStandard,
          }}
          hoverStyle={{
            ...shadowStandard,
          }}
        >
          <SolitoImage alt="app store" width={148} src={AppStoreImage} />
        </SmartLink>
        <SmartLink
          href={`https://play.google.com/store/apps/details?id=com.studentsaviour.app&pli=1`}
          Component={Stack}
          aspectRatio={148 / 44}
          h={44}
          w={148}
          br="$3"
          focusStyle={{
            ...shadowStandard,
          }}
          hoverStyle={{
            ...shadowStandard,
          }}
        >
          <SolitoImage alt="google play" width={148} src={GooglePlayImage} />
        </SmartLink>
      </XStack>
    </YStack>
  )
}
