import { Button, SizableText, SmartLink, Stack, XStack, YStack, shadowStandard } from '@my/ui'
import { Logo } from 'app/components'
import { SolitoImage } from 'solito/image'

interface LandingHeroProps {
  title: string
  subtitle: string
  image: {
    url: string
  }
}

export const LandingHero = (props: LandingHeroProps) => {
  const { title, subtitle, image } = props
  return (
    <YStack
      fd="column-reverse"
      br="$5"
      bg="$white"
      overflow="hidden"
      $gtSm={{ fd: 'row', minHeight: 450 }}
      {...shadowStandard}
    >
      <YStack
        jc="center"
        ai="center"
        p="$3.5"
        gap="$3.5"
        $gtSm={{ ai: 'stretch', p: '$8', gap: '$6', flexBasis: '50%' }}
      >
        <Logo width={102} />
        <YStack gap="$1">
          <SizableText tag="h1" size="$9.5" fow="600" ta="center" $gtSm={{ ta: 'left' }}>
            {title}
          </SizableText>
          <SizableText ta="center" size="$3" color="$overlay11" $gtSm={{ ta: 'left' }}>
            {subtitle}
          </SizableText>
        </YStack>
        <XStack gap="$3.5">
          <SmartLink Component={Button} href="/sign-up" theme="primary" br="$3">
            Join now!
          </SmartLink>
          <SmartLink Component={Button} href="/log-in" variant="outlined" br="$3">
            Log in
          </SmartLink>
        </XStack>
      </YStack>
      <Stack
        flexGrow={1}
        height={300}
        width="100%"
        overflow="hidden"
        $gtXs={{ height: 350 }}
        $gtSm={{ flexBasis: '50%', height: 'auto' }}
      >
        <SolitoImage
          src={image.url}
          fill
          contentFit="cover"
          alt="Student Saviour"
          sizes="(max-width: 600px) 100vw, 450px"
          priority
          loading="eager"
        />
      </Stack>
    </YStack>
  )
}
