import { QueryHookOptions, gql } from '@apollo/client'
import { usePersistedCacheQuery } from 'app/hooks'

export const getLandingPageQuery = gql`
  query LandingPageQuery {
    HomePage {
      hero {
        title
        subtitle
        image {
          url
        }
      }
      features {
        title
        description
        icon
        link
        image {
          url
        }
      }
      meta {
        title
        description
      }
    }
  }
`

export interface LandingPageQueryInput { }

export interface LandingPageQueryOutput {
  HomePage: {
    hero: {
      title: string
      subtitle: string
      image: {
        url: string
      }
    }
    features: {
      title: string
      description: string
      icon: string
      link: string
      image: {
        url: string
      }
    }[]
    meta: {
      title: string
      description: string
    }
  }
}

export const useLandingPageQuery = (
  options?: QueryHookOptions<LandingPageQueryOutput>
) => usePersistedCacheQuery<LandingPageQueryOutput>(getLandingPageQuery, { ...options })

export const landingPageQuery = { query: getLandingPageQuery }
