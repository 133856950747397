import { getTokens, getVariable, useTheme } from 'tamagui'
import React from 'react'

export function themed<A extends React.FC>(Component: A) {
  const wrapped = (props: any) => {
    const theme = useTheme()
    const tokens = getTokens()
    return (
      <Component
        {...props}
        // Resolve the theme tokens
        color={tokens.color[props.color]?.val || props.color || '#000'}
        size={
          typeof props.size === 'string' ? getVariable(theme[props.size] || props.size) : props.size
        }
      />
    )
  }
  return wrapped as unknown as A
}
