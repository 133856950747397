import { memo } from 'react'
import { IconProps } from '../IconProps'
import { IconBase } from '../IconBase'
import { themed } from '../themed'
import { Path } from 'react-native-svg'

const Icon = (props) => {
  const { color = 'black', size = 24, ...otherProps } = props
  return (
    <IconBase
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...otherProps}
    >
      <Path
        fill={`${color}`}
        fillRule="evenodd"
        d="M2 6.972V17.27c1.897-.712 5.408-1.42 10.261.17h.002c5.123 1.687 8.326.637 9.437.134V7.244c-1.843.626-5.256 1.198-10.062-.384C6.445 5.157 3.115 6.414 2 6.972ZM.988 5.243c1.402-.73 5.331-2.232 11.274-.283 4.542 1.495 7.571.84 8.965.33h.001c1.213-.44 2.472.467 2.472 1.74v10.64c0 .716-.413 1.37-1.065 1.675-1.434.676-5.198 1.904-10.997-.005-4.536-1.486-7.642-.716-9.103-.133C1.292 19.714 0 18.772 0 17.49V6.88c0-.675.37-1.306.978-1.631l.01-.006Z"
        clipRule="evenodd"
      />
      <Path
        fill={`${color}`}
        fillRule="evenodd"
        d="M12.28 10.89a1.29 1.29 0 1 0 0 2.58 1.29 1.29 0 0 0 0-2.58Zm-3.29 1.29a3.29 3.29 0 1 1 6.58 0 3.29 3.29 0 0 1-6.58 0Z"
        clipRule="evenodd"
      />
      <Path
        fill={`${color}`}
        d="M18.73 13.17a.99.99 0 1 0 0-1.98.99.99 0 0 0 0 1.98ZM4.97 13.17a.99.99 0 1 0 0-1.98.99.99 0 0 0 0 1.98Z"
      />
    </IconBase>
  )
}

Icon.displayName = 'IconCash'

export const IconCash = memo<IconProps>(themed(Icon))
