import { IconCash, SizableText, SmartLink, Stack, YStack, shadowStandard, useMedia } from '@my/ui'
import { CalendarDays, Home, Users } from '@tamagui/lucide-icons'
import { SolitoImage } from 'solito/image'

interface LandingFeatureCardProps {
  title: string
  description: string
  icon: string
  link: string
  image: {
    url: string
  }
  reverse?: boolean
}

const iconMap = {
  CalendarDays: CalendarDays,
  Cash: IconCash,
  Home: Home,
  Users: Users,
}

export const LandingFeatureCard = (props: LandingFeatureCardProps) => {
  const media = useMedia()
  const { title, description, image, link, icon, reverse } = props
  const Icon = iconMap[icon]
  return (
    <SmartLink
      Component={YStack}
      href={link}
      br="$5"
      bg="$white"
      overflow="hidden"
      $gtSm={{ fd: reverse ? 'row-reverse' : 'row', minHeight: 450 }}
      hoverStyle={{
        opacity: 0.8,
      }}
      focusStyle={{
        opacity: 0.8,
      }}
      pressStyle={{
        opacity: 0.75,
      }}
      {...shadowStandard}
    >
      <YStack
        jc="center"
        ai="center"
        p="$3.5"
        gap="$3.5"
        $gtSm={{ ai: 'stretch', p: '$8', flexBasis: '50%' }}
      >
        <Stack
          jc="center"
          ai="center"
          w={44}
          h={44}
          br={10000}
          bg="$white"
          $gtSm={{
            w: 56,
            h: 56,
          }}
          {...shadowStandard}
        >
          <Icon size={media.gtSm ? 36 : 28} color="$primary" />
        </Stack>
        <YStack gap="$1">
          <SizableText
            tag="h2"
            size="$8.5"
            fow="600"
            ta="center"
            $gtSm={{ ta: 'left', size: '$9.5', fow: '600' }}
          >
            {title}
          </SizableText>
          <SizableText ta="center" size="$3" color="$overlay11" $gtSm={{ ta: 'left' }}>
            {description}
          </SizableText>
        </YStack>
      </YStack>
      <Stack
        flexGrow={1}
        height={300}
        width="100%"
        overflow="hidden"
        $gtXs={{ height: 350 }}
        $gtSm={{ flexBasis: '50%', height: 'auto' }}
      >
        <SolitoImage
          src={image.url}
          fill
          contentFit="cover"
          alt={title}
          sizes="(max-width: 600px) 100vw, 450px"
        />
      </Stack>
    </SmartLink>
  )
}
