import { NextSeo } from 'next-seo'
import React from 'react'
import config from 'app/config'
import { MetaProps } from './meta-props'

export const Meta = ({ title, description, image }: MetaProps) => {
  return (
    <NextSeo
      title={title}
      description={description}
      openGraph={{
        title,
        description,
        images: image
          ? [
              {
                url: image,
                width: 1200,
                height: 630,
              },
            ]
          : undefined,
      }}
      nofollow={config.appEnv !== 'production'}
      noindex={config.appEnv !== 'production'}
    />
  )
}
